
import store from '@/store';
import {Component, Vue, Prop} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import PageMixin from "@/mixins/PageMixin.vue";
import {Getter} from "vuex-class";

@Component({
    name: "Home"
})
export default class Home extends mixins(PageMixin) {
    pageTitle = '';

    @Getter('userFirstName') firstName!: string;

    goToMore() {
        window.open("https://www.wizim.io/wizim-pour-une-entreprise", "_blank");
    }
    goToContact() {
        window.open("https://www.wizim.io/nous-contacter", "_blank");
    }

    goTo(url:string) {
        window.open(url, "_blank");
    }

    beforeMount() {
        this.pageTitle = <string>this.$t('page.home.title', {collab: this.firstName});
    }
}
